import React from "react";
import { AsideProps } from "@airbnb/lunar-layouts/lib/components/Aside";
import Layout, {
  LayoutProps,
} from "@airbnb/lunar-layouts/lib/components/Layout";
import { createStyleSheetAppLayout } from "./styles";
import { NavigationBar } from "../NavigationBar";

export function AppLayout({ styleSheet, ...props }: LayoutProps & AsideProps) {
  return (
    <Layout
      {...props}
      before={<NavigationBar />}
      styleSheet={styleSheet ?? createStyleSheetAppLayout({ maxWidth: 976 })}
    />
  );
}

export { createStyleSheetAppLayout } from "./styles";
