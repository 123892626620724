import React from "react";
import useStyles, { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetNavigationBar } from "./styles";

export function NavigationBar({ styleSheet }: { styleSheet?: StyleSheet }) {
  const [styles, cx] = useStyles(styleSheet ?? styleSheetNavigationBar);
  return (
    <div className={cx(styles.navigationBar)}>
      <div className={cx(styles.wrapper)}>
        <a
          className={cx(styles.brand)}
          href="https://www.going-merry.studio/"
        >
          <img
            src="https://uploads-ssl.webflow.com/5f7ab9ce9bdf806f5f03135b/5f7abff6964e878353213d4a_logo_white.svg"
            alt="Logo"
          />
        </a>
        <div className={cx(styles.menuWrapper)}>
          <nav className={cx(styles.menu)}>
            <a href="https://www.going-merry.studio/">Home</a>
            <a href="https://www.going-merry.studio/about">About</a>
          </nav>
        </div>
      </div>
    </div>
  );
}
