import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import {
  styleSheetSheet,
  styleSheetSheetArea,
} from "@airbnb/lunar/lib/components/Sheet/styles";

export const stylePresentSheetSheet: StyleSheet = (style) => {
  // https://github.com/airbnb/lunar/blob/master/packages/core/src/components/Sheet/styles.ts
  const defaultStyles: any = styleSheetSheet(style);
  return {
    ...defaultStyles,
    sheet: {
      ...defaultStyles.sheet,
      boxShadow: "none",
    },
    wrapper: {
      ...defaultStyles.wrapper,
      marginTop: "80px",
      borderRadius: "24px 24px 0 0",
      "@selectors": {
        "> div:first-child": {
          // NOTE:- Header
          borderRadius: "24px 24px 0 0",
          position: "sticky",
          top: 0,
          background: style.color.accent.bg,
          zIndex: 1,
        },
        "> div:first-child > div": {
          "@media": {
            [style.responsive.xsmall]: {
              margin: 3 * style.unit,
            },
          },
        },
      },
    },
    content: {
      ...defaultStyles.content,
      "@media": {
        [style.responsive.xsmall]: {
          padding: 3 * style.unit,
          paddingTop: 0,
        },
      },
    },
    container_gap: {
      ...defaultStyles.container_gap,
      gridTemplateColumns: "0fr 1fr 1fr 1fr",
    },
    sheet_slide_out: {
      animationFillMode: "forwards",
      animationName: {
        name: "sheetSlideOut",
        from: {
          opacity: 1,
          transform: "translateY(0%)",
        },
        to: {
          opacity: 0,
          transform: "translateY(80%)",
        },
      },
      animationTimingFunction: "ease-in",
    },
    sheet_slide_in: {
      animationFillMode: "forwards",
      animationName: {
        name: "sheetSlideIn",
        from: {
          opacity: 0,
          transform: "translateY(80%)",
        },
        to: {
          opacity: 1,
          transform: "translateY(0%)",
        },
      },
      animationTimingFunction: "ease-out",
    },
  };
};

export const styleSheetPresentSheetArea: StyleSheet = (style) => {
  const defaultStyles: any = styleSheetSheetArea(style);
  return {
    ...defaultStyles,
  };
};
