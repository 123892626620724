import React from "react";
import Spacing from "@airbnb/lunar/lib/components/Spacing";
import { SpotTimeSelect } from "../components/SpotTimeSelect";
import { useCheckSelectUseCase } from "../hooks/useCheckSelectUseCase";
import ErrorMessage from "@airbnb/lunar/lib/components/ErrorMessage";
import { useHistory } from "react-router";
import useTheme from "@airbnb/lunar/lib/hooks/useTheme";
import useMedia from "use-media-easy";
import Card from "@airbnb/lunar/lib/components/Card";
import CardContent from "@airbnb/lunar/lib/components/Card/Content";
import DateTime from "@airbnb/lunar/lib/components/DateTime";
import Text from "@airbnb/lunar/lib/components/Text";
import ButtonGroup from "@airbnb/lunar/lib/components/ButtonGroup";
import Button from "@airbnb/lunar/lib/components/Button";

interface SelectTimeAppProps {
  start: Date;
  end: Date;
  onChangeStart: (date: Date) => void;
  onChangeEnd: (date: Date) => void;
}

export const SelectTimeApp = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
}: SelectTimeAppProps) => {
  const theme = useTheme();
  const [isMobileMode] = useMedia({ query: theme.responsive.xsmall });
  const history = useHistory();
  const { check, checking, error } = useCheckSelectUseCase(
    { start, end },
    (url) => {
      history.push(`${url}`);
    }
  );

  return (
    <Card>
      <CardContent>
        {!isMobileMode && (
          <Spacing bottom={3}>
            <Text startAlign>
              <Text bold large>
                {DateTime.format({
                  at: start,
                  format: "yyyy/MM/ddを選択中...",
                })}
              </Text>
              <Text small>選択後、予約フォームにお進みください</Text>
            </Text>
          </Spacing>
        )}
        {error && (
          <Spacing bottom={3}>
            <ErrorMessage title="エラー" error={error} />
          </Spacing>
        )}
        <SpotTimeSelect
          hideDate
          label={<label>開始</label>}
          value={start}
          inline
          large={isMobileMode}
          minuteStep={30}
          onChange={(changed) => onChangeStart(new Date(changed))}
        />
        <SpotTimeSelect
          hideDate
          label={<label>終了</label>}
          value={end}
          inline
          large={isMobileMode}
          minuteStep={30}
          onChange={(changed) => onChangeEnd(new Date(changed))}
        />
      </CardContent>
      <CardContent>
        <ButtonGroup stacked>
          <Button block onClick={() => check()} loading={checking}>
            予約フォームに進む
          </Button>
        </ButtonGroup>
      </CardContent>
    </Card>
  );
};
