import React from "react";
import Sheet, {
  BaseSheetProps,
  SheetArea,
} from "@airbnb/lunar/lib/components/Sheet";
import { SheetAreaProps } from "@airbnb/lunar/lib/components/Sheet/SheetArea";
import { stylePresentSheetSheet, styleSheetPresentSheetArea } from "./styles";

export function PresentSheet({ styleSheet, ...props }: BaseSheetProps) {
  return <Sheet {...props} styleSheet={styleSheet ?? stylePresentSheetSheet} />;
}

export function PresentSheetArea({ styleSheet, ...props }: SheetAreaProps) {
  return (
    <SheetArea
      {...props}
      styleSheet={styleSheet ?? styleSheetPresentSheetArea}
    />
  );
}
