import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetLayout } from "@airbnb/lunar-layouts/lib/components/Layout/styles";

export const createStyleSheetAppLayout = ({
  maxWidth,
}: {
  maxWidth?: number;
}): StyleSheet => {
  return (style) => {
    // NOTE:- https://github.com/airbnb/lunar/blob/master/packages/layouts/src/components/Layout/styles.ts
    const defaultStyles: any = styleSheetLayout(style);
    return {
      ...defaultStyles,
      layout: {
        ...defaultStyles.layout,
        flexDirection: "column",
      },
      mainContent: {
        paddingTop: 24,
        maxWidth: maxWidth ?? 976, // NOTE:- same size on airbnb.jp
      },
    };
  };
};
