import React from "react";
import { styleSheetDesktopLayout } from "./styles";
import { TwoColumnLayoutProps } from "@airbnb/lunar-layouts/lib/components/TwoColumnLayout";
import Layout from "@airbnb/lunar-layouts/lib/components/Layout";

export function DesktopLayout({
  styleSheet,
  aside,
  after,
  before,
  children,
  ...props
}: TwoColumnLayoutProps) {
  return (
    <Layout
      {...props}
      styleSheet={styleSheet ?? styleSheetDesktopLayout}
      minHeight="auto"
      after={after ? aside : null}
      before={before ? aside : null}
    >
      {children}
    </Layout>
  );
}
