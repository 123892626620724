import React, { useState, Suspense } from "react";
import { Spot } from "../domains";
import { useGetSpotListingsUseCase } from "../hooks/useGetSpotsUseCase";
import useStyles from "@airbnb/lunar/lib/hooks/useStyles";
import { fluidDatePickerStyleSheet } from "../components/FluidDatePicker/styles";
import { FluidDatePicker } from "../components/FluidDatePicker";
import { DayModifiers } from "react-day-picker/types/common";
import useMedia from "use-media-easy";
import { DayPickerProps } from "react-day-picker";
import { getCustomModifiers } from "@airbnb/lunar/lib/utils/datePicker";
import { AppLoader } from "../components/AppLoader";
import { DayButton } from "../components/DayButton";
import { useGetLastSpotListingUseCase } from "../hooks/useGetLastSpotUseCase";

interface SelectCalendarAppProps {
  day?: Date;
  onDayClick?: DayPickerProps["onDayClick"];
}

export const SelectCalendarApp = (props: SelectCalendarAppProps) => {
  return (
    <Suspense fallback={<AppLoader />}>
      <InnerApp {...props} />
    </Suspense>
  );
};

const InnerApp = (props: SelectCalendarAppProps) => {
  const now = new Date();
  const [styles, cx] = useStyles(fluidDatePickerStyleSheet);
  const [isMultipleCalendar] = useMedia({ query: { maxWidth: 980 } });
  const [month, setMonth] = useState(now);
  const { data } = useGetSpotListingsUseCase(month);
  const { data: last } = useGetLastSpotListingUseCase();
  const availability = Spot.getDateByAvailability(data, { before: now });
  const modifiers = {
    [cx(styles.modifier_before)]: { before: now, after: last.startDate },
    [cx(styles.modifier_today)]: now,
    [cx(styles.modifier_sundays)]: { daysOfWeek: [0] },
    [cx(styles.modifier_saturdays)]: { daysOfWeek: [6] },
  };

  return (
    <FluidDatePicker
      styleSheet={fluidDatePickerStyleSheet}
      disabledDays={[
        { before: now, after: last.startDate },
        ...availability.unavailable,
      ]}
      locale="ja"
      initialMonth={now}
      selectedDays={props.day}
      fromMonth={now}
      modifiers={getCustomModifiers(modifiers, styles, cx)}
      numberOfMonths={isMultipleCalendar ? 1 : 2}
      onMonthChange={(month: Date) => setMonth(month)}
      onDayClick={props.onDayClick}
      renderDay={(date: Date, modifiers: DayModifiers) => {
        return (
          <DayButton
            date={date}
            selected={modifiers[cx(styles.modifier_selected)]}
            disabled={modifiers[cx(styles.modifier_disabled)]}
            before={modifiers[cx(styles.modifier_before)]}
            after={modifiers[cx(styles.modifier_after)]}
            warning={availability.isWarning(date)}
            available={availability.isAvailable(date)}
            notAvailable={availability.isNotAvailable(date)}
          />
        );
      }}
    />
  );
};
