import { ValueObject } from "ddd-base";

interface IEstimateProps {
  number: number;
  price: number;
  moviePrice: number;
  estimate: number;
}

export interface IEstimateJSON {
  number: number;
  price: number;
  moviePrice: number;
  estimate: number;
}

export class Estimate extends ValueObject<IEstimateProps> {
  price(is_movie: boolean) {
    const { estimate } = this.props;
    return estimate;
  }

  toJSON() {
    return {
      ...this.props,
    };
  }
}
