import React from "react";
import Loader, { LoaderProps } from "@airbnb/lunar/lib/components/Loader";
import useStyles from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetAppLoader } from "./styles";
import useTheme from "@airbnb/lunar/lib/hooks/useTheme";

export function AppLoader({
  styleSheet,
  padding,
  ...props
}: LoaderProps & { padding?: number | string }) {
  const theme = useTheme();
  const [styles, cx] = useStyles(styleSheet ?? styleSheetAppLoader);
  return (
    <div
      className={cx(styles.appLoader, styles.appLoader_centered, {
        padding: padding || theme.unit * 10,
      })}
    >
      <div className={cx(styles.errorOrLoader)}>
        <Loader inline />
      </div>
    </div>
  );
}
