import React, { Suspense, useEffect, useState } from "react";
import { AppLoader } from "../components/AppLoader";
import Spacing from "@airbnb/lunar/lib/components/Spacing";
import Text from "@airbnb/lunar/lib/components/Text";
import DateTime from "@airbnb/lunar/lib/components/DateTime";
import { delay } from "../utils";

const LazyTimeBarApp = React.lazy(() => import("./lazy/TimeBarApp"));

interface TimeBarAppProps {
  day: Date;
  start?: Date;
  end?: Date;
}

export const TimeBarApp = ({ day, ...props }: TimeBarAppProps) => {
  const [swaping, setSwaping] = useState(false);
  useEffect(() => {
    const swap = async () => {
      setSwaping(true);
      await delay(600);
      setSwaping(false);
    };
    swap();
  }, [day]);
  return (
    <Suspense fallback={<AppLoader padding={20} />}>
      {day && !swaping && (
        <Spacing bottom={3}>
          <Text startAlign>
            <Text bold>
              {DateTime.format({
                at: day,
                format: "yyyy/MM/ddの予約状況",
              })}
            </Text>
            <Text small>予約可能な時間帯をご確認ください</Text>
          </Text>
        </Spacing>
      )}
      {swaping ? (
        <AppLoader padding={20} />
      ) : (
        <LazyTimeBarApp day={day} {...props} />
      )}
    </Suspense>
  );
};
