import React from "react";
import ReactDOM from "react-dom";
import Core from "@airbnb/lunar/lib/index";
import AppShell from "@airbnb/lunar-app-shell";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StudioGoingMerryLight } from "./themes";
import { translator } from "./locale";

const AppName = "StudioGoingMerry";

Core.initialize({
  defaultLocale: "ja",
  defaultTimezone: "JST",
  logger: console.log,
  name: AppName,
  theme: `${AppName}-light`,
  translator: translator,
});
Core.aesthetic.extendTheme(
  `${AppName}-light`,
  "light",
  StudioGoingMerryLight(Core.fontFamily())
);

ReactDOM.render(
  <React.StrictMode>
    <AppShell name={AppName}>
      <App />
    </AppShell>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
