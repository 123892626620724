import axios from "axios";
import { useQueryWithSuspense } from "./useQueryWithSuspense";
import { Spot, SpotSerializer } from "../domains";

export function useGetLastSpotListingUseCase() {
  return useQueryWithSuspense<Spot>(["last"], getLastSpot, {
    suspense: true,
  });
}

const getLastSpot = async () => {
  const { data } = await axios.get(
    process.env.REACT_APP_API_BASE_URL + `/spot/last`,
    {
      headers: {
        "content-type": "application/json",
        "x-access-token": process.env.REACT_APP_API_ACCESS_TOKEN,
      },
    }
  );
  return SpotSerializer.fromJSON(data);
};
