import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetLayout } from "@airbnb/lunar-layouts/lib/components/Layout/styles";

export const styleSheetDesktopLayout: StyleSheet = (style) => {
  // NOTE:- https://github.com/airbnb/lunar/blob/master/packages/layouts/src/components/Layout/styles.ts
  const defaultStyles: any = styleSheetLayout(style);
  return {
    ...defaultStyles,
    mainContent: {
      position: "sticky",
      top: 86,
    },
  };
};
