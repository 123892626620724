import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import {
  SelectDayApp,
  SelectTimeApp,
  SelectCalendarApp,
  TimeBarApp,
} from "./apps";
import Card from "@airbnb/lunar/lib/components/Card";
import CardContent from "@airbnb/lunar/lib/components/Card/Content";
import Loader from "@airbnb/lunar/lib/components/Loader";
import Text from "@airbnb/lunar/lib/components/Text";

const FormApp = lazy(() => import("./apps/FormApp"));
const ThanksApp = lazy(() => import("./apps/ThanksApp"));

function App() {
  // NOTE:- Global App States
  const [day, setDay] = useState<Date | undefined>(undefined);
  const [start, setStart] = useState<Date | undefined>(day);
  const [end, setEnd] = useState<Date | undefined>(day);
  useEffect(() => {
    setStart(day);
    setEnd(day);
  }, [day]);
  const [sheetVisible, setSheetVisible] = useState(false);

  const empty = (
    <Card>
      <CardContent>
        <Text>← 予約日を選択してください。</Text>
      </CardContent>
    </Card>
  );

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/" exact>
              <SelectDayApp
                sheetVisible={sheetVisible}
                onSheetClose={() => setSheetVisible(false)}
                calendarApp={
                  <SelectCalendarApp
                    day={day}
                    onDayClick={(selected) => {
                      const day = selected;
                      day.setHours(8);
                      setDay(day);
                      setSheetVisible(true);
                    }}
                  />
                }
                timeApp={
                  start && end ? (
                    <SelectTimeApp
                      start={start}
                      end={end}
                      onChangeStart={setStart}
                      onChangeEnd={setEnd}
                    />
                  ) : (
                    empty
                  )
                }
                timeBarApp={
                  day && <TimeBarApp day={day} start={start} end={end} />
                }
              />
            </Route>
            <Route path="/form/:start/:end" exact component={FormApp} />
            <Route path="/thanks" exact component={ThanksApp} />
          </Switch>
        </Suspense>
      </Router>
      <ReactQueryDevtools initialIsOpen />
    </div>
  );
}

export default App;
