import React from "react";
import LayoutShell from "@airbnb/lunar-layouts";
import useMedia from "use-media-easy";
import useTheme from "@airbnb/lunar/lib/hooks/useTheme";
import Spacing from "@airbnb/lunar/lib/components/Spacing";
import Text from "@airbnb/lunar/lib/components/Text";
import OneColumnLayout from "@airbnb/lunar-layouts/lib/components/OneColumnLayout";
import Aside from "@airbnb/lunar-layouts/lib/components/Aside";
import {PresentSheet, PresentSheetArea} from "../components/PresentSheet";
import {AppLayout} from "../components/AppLayout";
import {DesktopLayout} from "../components/DesktopLayout";

export const SelectDayApp = (props: any) => {
  const theme = useTheme();
  const [isMobileMode] = useMedia({query: theme.responsive.xsmall});
  const CalendarAppHeader = (
    <Text startAlign>
      <Text bold large>
        1. 予約日を選択
      </Text>
      <Text small>日付を選択すると予約可能な日時が表示されます</Text>
    </Text>
  );

  const TimeAppHeader = (
    <Text startAlign>
      <Text bold large>
        2. 予約時間を選択
      </Text>
      <Text small>予約時間をお選びください</Text>
    </Text>
  );

  return (
    <PresentSheetArea>
      <LayoutShell>
        <AppLayout centerAlign noBackground>
          {isMobileMode ? (
            <OneColumnLayout noBackground noPadding>
              {CalendarAppHeader}
              {props.calendarApp}
              <PresentSheet
                header={TimeAppHeader}
                gap
                noAnimation={false}
                portal={false}
                visible={props.sheetVisible}
                compact={false}
                onClose={props.onSheetClose}
              >
                {props.timeBarApp}
                {props.timeApp}
              </PresentSheet>
            </OneColumnLayout>
          ) : (
            <DesktopLayout
              noBackground
              after
              aside={
                <Aside width={350}>
                  <Spacing bottom={4}>{TimeAppHeader}</Spacing>
                  {props.timeApp}
                </Aside>
              }
            >
              <Spacing bottom={3}>{CalendarAppHeader}</Spacing>
              <Spacing>{props.calendarApp}</Spacing>
              {props.timeBarApp}
            </DesktopLayout>
          )}

          <Text startAlign>
            <Text bold large>
              予約について
            </Text>
            <ul>
              <li>こちらの予約フォームより仮予約の申込み（キープ）が行なえます。<br/>仮予約後、スタッフより折り返し本予約に関するご案内をさせていただきます。</li>
              <li>最低利用時間は1時間です</li>
              <li>予約時間内に搬入／撤収を完了させてください。</li>
              <li>防音設備はございません。また、他フロアはオフィス利用されています。</li>
              <li>日没後にストロボを利用する際は必ず完全遮光ください。（備え付けのシャッターおよび遮光カーテンを利用いただけます）</li>
              <li>インターネット回線はベストエフォートとなります。回線速度を保証するものではございません。</li>
              <li>延長の場合は 10 分単位で延長料金を請求いたします。</li>
              <li>キャンセルは本予約後、2週間前までは無料で承ります。それ以降、7日前までは30%、5日前までは50%、前日までは70%、当日は100%をキャンセル料としていただきます。</li>
              <li>内覧の希望も公式サイトのチャットおよび問い合わせフォームにて承っております。</li>
            </ul>
          </Text>
          <Text startAlign>
            <Text bold large>
              禁止事項
            </Text>
            <Text>下記、禁止行為が認められた場合は、予約成立の如何に関わらず、ただちに当施設の利用をお断りさせていただきます。</Text>
            <ul>
              <li>成人向けコンテンツの撮影は固く禁じます。</li>
              <li>暴力団、暴力団関係企業・団体、総会屋、過激行動団体、その他反社会勢力もしくはこれらに準じる者または暴力団等の関係者の利用は固く禁じます。</li>
            </ul>
          </Text>
          <Text startAlign>
            <Text bold large>
              料金
            </Text>
            <ul>
              <li>基本料金 8,000円/h</li>
              <li>8時間パック 55,000円</li>
              <li>1日パック 90,000円</li>
            </ul>
            <Text>※予約時に表示される料金は自動で最もお得な料金となります</Text>
          </Text>
        </AppLayout>
      </LayoutShell>
    </PresentSheetArea>
  );
};
