import React from "react";
import useStyles, { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetDayButton } from "./styles";

export type DayButtonProps = {
  date: Date;
  /** Content within the label. */
  children?: React.ReactNode;
  /** Custom style sheet. */
  styleSheet?: StyleSheet;
  before?: boolean;
  after?: boolean;
  selected?: boolean;
  warning?: boolean | undefined;
  available?: boolean;
  notAvailable?: boolean;
  disabled?: boolean;
};

//https://dribbble.com/shots/6307144-Todo/attachments
export function DayButton({
  before,
  after,
  disabled,
  available,
  notAvailable,
  selected,
  warning,
  date,
  children,
  styleSheet,
}: DayButtonProps) {
  const [styles, cx] = useStyles(styleSheet ?? styleSheetDayButton);
  return (
    <div
      className={cx(
        styles.dayButton,
        selected && styles.selected,
        before && styles.before,
        after && styles.after
      )}
    >
      <div className={cx(styles.date)}>{date.getDate()}</div>
      {!disabled && available && !warning && (
        <span
          className={cx(styles.status, !selected && styles.status_available)}
        >
          <svg viewBox="0 0 8 8" fill="currentColor">
            <rect fill="currentColor" x="1" y="1" width="6" height="6" rx="3" />
          </svg>
        </span>
      )}
      {!disabled && available && warning && (
        <span className={cx(styles.status, !selected && styles.status_warning)}>
          <svg viewBox="0 0 8 8" fill="currentColor">
            <path d="M3.55875 1.32795C3.74694 0.974832 4.25306 0.974832 4.44125 1.32795L7.0723 6.26484C7.24979 6.59789 7.00844 7 6.63105 7H1.36893C0.99154 7 0.75019 6.59789 0.927684 6.26484L3.55875 1.32795Z" />
          </svg>
        </span>
      )}
      {!before && notAvailable && (
        <span
          className={cx(
            styles.status,
            !selected && styles.status_not_available
          )}
        >
          <svg viewBox="0 0 8 8" fill="currentColor">
            <path d="M4,2.58578644 L6.29289322,0.292893219 C6.68341751,-0.0976310729 7.31658249,-0.0976310729 7.70710678,0.292893219 C8.09763107,0.683417511 8.09763107,1.31658249 7.70710678,1.70710678 L5.41421356,4 L7.70710678,6.29289322 C8.09763107,6.68341751 8.09763107,7.31658249 7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 L4,5.41421356 L1.70710678,7.70710678 C1.31658249,8.09763107 0.683417511,8.09763107 0.292893219,7.70710678 C-0.0976310729,7.31658249 -0.0976310729,6.68341751 0.292893219,6.29289322 L2.58578644,4 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L4,2.58578644 Z" />
          </svg>
        </span>
      )}
    </div>
  );
}
