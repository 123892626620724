import React from "react";
import { NavbarElementProps } from "react-day-picker";
import useStyles, { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import IconButton from "@airbnb/lunar/lib/components/IconButton";
import DirectionalIcon from "@airbnb/lunar/lib/components/DirectionalIcon";
import IconChevronLeft from "@airbnb/lunar-icons/lib/interface/IconChevronLeft";
import IconChevronRight from "@airbnb/lunar-icons/lib/interface/IconChevronRight";
import { fluidDatePickerStyleSheet } from "../styles";

export type NavBarProps = NavbarElementProps & {
  /** Callback for a reset button. */
  onResetClick?: () => void;
  /** Show the reset button. */
  showResetButton?: boolean;
  /** Whether there is a footer to account for positioning. */
  noFooter?: boolean;
  /** Custom style sheet. */
  styleSheet?: StyleSheet;
};

export default function NavBar({
  className,
  classNames,
  labels,
  noFooter,
  showNextButton,
  showPreviousButton,
  showResetButton,
  onNextClick,
  onPreviousClick,
  onResetClick,
  styleSheet,
}: NavBarProps) {
  const [styles, cx] = useStyles(styleSheet ?? fluidDatePickerStyleSheet);

  const handleNextClick = () => {
    onNextClick();
  };

  const handlePreviousClick = () => {
    onPreviousClick();
  };

  const handleResetClick = () => {
    if (onResetClick) {
      onResetClick();
    }
  };

  return (
    <div className={className}>
      {showPreviousButton && (
        <div className={classNames.navButtonPrev}>
          <IconButton
            tooltip={labels.previousMonth}
            onClick={handlePreviousClick}
          >
            <DirectionalIcon
              direction="left"
              left={IconChevronLeft}
              right={IconChevronRight}
              size="2em"
              accessibilityLabel={labels.previousMonth}
            />
          </IconButton>
        </div>
      )}

      {showNextButton && (
        <div className={classNames.navButtonNext}>
          <IconButton tooltip={labels.nextMonth} onClick={handleNextClick}>
            <DirectionalIcon
              direction="right"
              left={IconChevronLeft}
              right={IconChevronRight}
              size="2em"
              accessibilityLabel={labels.nextMonth}
            />
          </IconButton>
        </div>
      )}

      {showResetButton && (
        <div
          className={cx(
            styles.resetButton,
            noFooter && styles.resetButton_noFooter
          )}
        >
          <button
            className={cx(styles.todayButton)}
            type="button"
            onClick={handleResetClick}
          >
            Reset
          </button>
        </div>
      )}
    </div>
  );
}
