import axios from "axios";
import { useMutation, queryCache } from "react-query";
import { useState } from "react";
import { delay } from "../utils";
import { Estimate } from "../domains";

export function useCheckSelectUseCase(
  { start, end }: { start: Date | undefined; end: Date | undefined },
  onSuccess: (url: string) => void
) {
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const startTimestamp = start ? start.getTime() / 1000 : undefined;
  const endTimestamp = end ? end.getTime() / 1000 : undefined;
  const predicate = ["estimate", startTimestamp, endTimestamp];
  const [check] = useMutation(
    async () => {
      const [res] = await Promise.all([
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + `/check`,
            {
              start: startTimestamp,
              end: endTimestamp,
            },
            {
              headers: {
                "content-type": "application/json",
                "x-access-token": process.env.REACT_APP_API_ACCESS_TOKEN,
              },
            }
          )
          .then((response: any) => response.data),
        delay(1000),
      ]);
      return res;
    },
    {
      onMutate: () => {
        setChecking(true);
        setError(undefined);
      },
      onSuccess: (data: any) => {
        queryCache.setQueryData(predicate, () => new Estimate(data));
        onSuccess(`/form/${startTimestamp}/${endTimestamp}`);
      },
      onError: (error: any) => {
        setError(new Error(error?.response?.data ?? "エラーが発生しました"));
      },
      onSettled: () => {
        setChecking(false);
      },
    }
  );
  return {
    check,
    checking,
    error,
  };
}
