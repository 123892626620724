import axios from "axios";
import { useQueryWithSuspense } from "./useQueryWithSuspense";
import { ISpotJSON, Spot, SpotSerializer } from "../domains";
import DateTime from "@airbnb/lunar/lib/components/DateTime";

export function useGetSpotListingsUseCase(at: Date) {
  return useQueryWithSuspense<Spot[]>(
    [
      "spots",
      DateTime.format({ at, format: "yyyy" }),
      DateTime.format({ at, format: "MM" }),
    ],
    getSpots,
    {
      suspense: true,
    }
  );
}

const getSpots = async (key: any, year: string, month: string) => {
  const { data } = await axios.get(
    process.env.REACT_APP_API_BASE_URL + `/calendar/monthly/${year}-${month}`,
    {
      headers: {
        "content-type": "application/json",
        "x-access-token": process.env.REACT_APP_API_ACCESS_TOKEN,
      },
    }
  );
  return data
    .map((json: ISpotJSON) => SpotSerializer.fromJSON(json))
    .sort((a: Spot, b: Spot) => a.startTimestamp - b.startTimestamp);
};
