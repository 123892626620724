import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
export const styleSheetNavigationBar: StyleSheet = (style) => {
  return {
    navigationBar: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 36,
      paddingRight: 36,
      position: "sticky",
      top: 0,
      zIndex: 100,
      width: "100%",
      gridAutoColumns: "1fr",
      gridColumnGap: 36,
      gridRowGap: 30,
      gridTemplateRows: "auto",
      borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
      backgroundColor: "white",
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 500,
      textTransform: "uppercase",
      color: "#18181d",
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gridAutoColumns: "1fr",
      gridColumnGap: 36,
      gridRowGap: 0,
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto",
    },
    menuWrapper: {
      gridRowStart: "span 1",
      gridRowEnd: "span 1",
      gridColumnStart: "span 1",
      gridColumnEnd: "span 1",
      justifySelf: "end",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flex: 1,
    },
    menu: {
      position: "relative",
      float: "right",
      lineHeight: "26px",
      "@selectors": {
        "> a": {
          marginRight: 10,
          marginLeft: 10,
          padding: 0,
          fontSize: 11,
          fontWeight: 600,
          position: "relative",
          display: "inline-block",
          verticalAlign: "top",
          textDecoration: "none",
          textAlign: "left",
        },
      },
    },
    brand: {
      gridRowStart: "span 1",
      gridRowEnd: "span 1",
      gridColumnStart: "span 1",
      gridColumnEnd: "span 1",
      justifySelf: "start",
      display: "flex",
      float: "left",
      textDecoration: "none",
      color: "#333",
      "@selectors": {
        "> img": {
          maxHeight: 37,
        },
      },
    },
  };
};
