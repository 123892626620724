import { Theme } from "@airbnb/lunar";
import buildTheme from "@airbnb/lunar/lib/themes/buildTheme";

const brand: Theme["color"]["brand"] = {
  luxury: [
    "#BFC1FF",
    "#8A88F0",
    "#665CDA",
    "#4D3CBE",
    "#3D279B",
    "#2F1A71",
    "#1E1040",
  ],
  plus: [
    "#FFBFE4",
    "#D467A1",
    "#AE336D",
    "#8C1848",
    "#6E0E31",
    "#550D23",
    "#40101C",
  ],
};

const color: Theme["color"]["core"] = {
  // Gray
  neutral: [
    "#F8F8F8",
    "#EBEBEB",
    "#D6D6D6",
    "#C2C2C2",
    "#767676",
    "#484848",
    "#303030",
  ],
  // Blue
  secondary: [
    "#E3ECF6",
    "#B9D0E8",
    "#8AB0D9",
    "#1561B2",
    "#0F4FA2",
    "#0C4599",
    "#06338A",
  ],
  // Any
  primary: [
    "#C2E3FF",
    "#99D1FF",
    "#70BFFF",
    "#48ACFF",
    "#1F9AFF",
    "#0087F5",
    "#0070CC",
  ],
  // Green
  success: [
    "#8FF5E9",
    "#6AF1E1",
    "#44EEDA",
    "#15E6CD",
    "#13CDB8",
    "#10A896",
    "#0C8375",
  ],
  // Yellow/Orange
  warning: [
    "#FFF5DB",
    "#FFE196",
    "#FFDA80",
    "#FFB400",
    "#F7A800",
    "#FF9C00",
    "#FF8C00",
  ],
  // Red/Pink
  danger: [
    "#F2BBC6",
    "#EB99A9",
    "#E4778D",
    "#D84B68",
    "#D73353",
    "#BB2543",
    "#991E37",
  ],
};

export const StudioGoingMerryLight = (fontFamily: string) =>
  buildTheme({
    base: "#fff",
    baseInverse: "#18181D",
    boxShadowColor: color.neutral[6],
    brand,
    color,
    fontFamily,
  });
