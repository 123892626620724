export class Validator {
  static isRequired(value: unknown) {
    const pass = Array.isArray(value) ? value.length : value;
    if (!pass) {
      throw new Error("必ず記入してください。");
    }
  }
  static isEmail(value: string) {
    Validator.isRequired(value);
    if (!/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i.test(value)) {
      throw new Error("正しい形式で入力ください。");
    }
  }

  static isZipcode(value: string) {
    Validator.isRequired(value);
    if (!/^\d{7}$/i.test(value)) {
      throw new Error("ハイフンなしの7桁の数字で入力してください。");
    }
  }
}
