import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetAppLoader as styleSheetLunarAppLoader } from "@airbnb/lunar/lib/components/AppLoader/styles";

export const styleSheetAppLoader: StyleSheet = (style) => {
  // https://github.com/airbnb/lunar/blob/master/packages/core/src/components/AppLoader/styles.ts
  const defaultStyles: any = styleSheetLunarAppLoader(style);
  return {
    ...defaultStyles,
  };
};
