import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";

export const styleSheetDayButton: StyleSheet = ({ color, font, pattern }) => ({
  dayButton: {
    display: "flex",
    flexDirection: "column",
    ":focus": {
      outline: "none",
    },
    margin: 2,
    borderRadius: "20%",
  },
  date: {
    display: "flex",
    width: "60%",
    height: "60%",
    alignItems: "center",
    justifyContent: "center",
    margin: 8,
    marginTop: 6,
    marginBottom: 12,
  },
  before: {
    opacity: 0.3,
  },
  after: {
    opacity: 0.3,
  },
  status: {
    position: "absolute",
    bottom: "10%",
    width: 8,
    height: 8,
    borderRadius: "50%",
    "@selectors": {
      "> svg": {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
  },
  status_available: {
    color: color.core.success[4],
  },
  status_not_available: {
    color: color.core.danger[3],
  },
  status_warning: {
    color: color.core.warning[4],
  },
  selected: {
    borderRadius: "50%",
    background: color.core.primary[3],
    color: "white",
  },
});
