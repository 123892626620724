import { Entity, Identifier, Serializer } from "ddd-base";
import groupBy from "lodash.groupby";
import memoize from "lodash.memoize";
import { DateUtils } from "react-day-picker";
import DateTime from "@airbnb/lunar/lib/components/DateTime";

export class SpotIdentifier extends Identifier<number> {}

// NOTE:- https://github.com/AgeEntertainment/reservation-system/blob/develop/src/Model/Entity/Spot.php#L27
enum SpotType {
  NOT_RESERVED,
  IN_USE,
  INTERVAL,
  KEEP,
  MAINTENANCE,
  OUT_OF_SERVICE,
}

interface ISpotProps {
  id: SpotIdentifier;
  minutes: number;
  canReserve: boolean;
  unixtime: number;
  type: SpotType;
}

export interface ISpotJSON {
  id: number;
  minutes: number;
  canReserve: boolean;
  unixtime: number;
  type: number;
}

// NOTE:- apply memoize function for performance issue...
const format = memoize(
  (at: number | Date, format: string) => DateTime.format({ at, format }),
  (at, format) => {
    return `${at}:${format}`;
  }
);

export class Spot extends Entity<ISpotProps> {
  static getDateByAvailability = (
    spots: Spot[],
    { before }: { before: Date }
  ): {
    available: Date[];
    unavailable: Date[];
    isAvailable: any;
    isNotAvailable: any;
    isWarning: any;
  } => {
    const groups: { [key: string]: Spot[] } = groupBy(spots, (spot: Spot) =>
      format(spot.startTimestamp, "yyyy-MM-dd")
    );
    let available: Date[] = [];
    let unavailable: Date[] = [];
    let warning: Date[] = [];
    for (const [day, items] of Object.entries(groups)) {
      // NOTE:- 将来的には、予約の逼迫度合いに応じて4パターンくらいに分けたい
      const canReserves = items.filter((item: Spot) => item.props.canReserve);
      const isWarning = items.length !== canReserves.length;
      const target = new Date(day);
      const [canReserve] = canReserves;
      canReserve && DateUtils.isDayAfter(target, before)
        ? available.push(target)
        : unavailable.push(target);
      isWarning && warning.push(target);
    }

    const isAvailable = (date: Date): boolean => {
      return available.some((d: Date) => DateUtils.isSameDay(d, date));
    };

    const isNotAvailable = (date: Date): boolean => {
      return unavailable.some((d: Date) => DateUtils.isSameDay(d, date));
    };

    const isWarning = (date: Date): boolean => {
      return warning.some((d: Date) => DateUtils.isSameDay(d, date));
    };

    return { available, unavailable, isAvailable, isNotAvailable, isWarning };
  };

  toJSON() {
    return {
      ...this.props,
      id: this.props.id.toValue(),
      type: this.props.type as number,
    };
  }

  isSameDay(date: Date): boolean {
    return DateUtils.isSameDay(date, this.startDate);
  }

  // NOTE:-
  // スポットの開始時刻(milliseconds)
  get startTimestamp(): number {
    const { unixtime } = this.props;
    return unixtime * 1000;
  }

  get startDate(): Date {
    return new Date(this.startTimestamp);
  }

  // NOTE:-
  // スポットの終了時刻(milliseconds)
  get endTimestamp(): number {
    const { minutes } = this.props;
    return this.startTimestamp + minutes * 60 * 1000;
  }

  // NOTE:-
  // 開始時間のテキスト
  get startTimeText(): string {
    return format(this.startTimestamp, "yyyy-MM-dd HH:mm:ss") ?? "";
  }

  // NOTE:-
  // 終了時間のテキスト
  get endTimeText(): string {
    return format(this.endTimestamp, "yyyy-MM-dd HH:mm:ss") ?? "";
  }

  // NOTE:-
  // ボタンのラベルテキスト
  get buttonLabel(): string {
    return `${this.startTimeText} - ${this.endTimeText}`;
  }

  // NOTE:-
  // 予約フォームのURL
  get formUrl(): string {
    const path = format(this.startTimestamp, "yyyy-MM");
    return path ? `${path}/${this.props.id.toValue()}` : "/error";
  }

  get canReserve(): boolean {
    return this.props.canReserve;
  }
}

export const SpotSerializer: Serializer<Spot, ISpotJSON> = {
  fromJSON(json: ISpotJSON): Spot {
    return new Spot({
      ...json,
      id: new SpotIdentifier(json.id),
      type: json.type as SpotType,
    });
  },
  toJSON(entity: Spot): ISpotJSON {
    return entity.toJSON();
  },
};
