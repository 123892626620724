import memoize from "lodash.memoize";
import { TranslateParams } from "@airbnb/lunar";
import locale from "./ja.json";

export const translator = memoize(
  (key, phrase, params) => translate(key, phrase, params),
  (key, phrase, params, options) => {
    return `${key}:${phrase}:${JSON.stringify(params)}}`;
  }
);

// NOTE:- https://github.com/airbnb/lunar/blob/master/packages/core/src/index.ts#L156-L180
function translate(key: string, phrase: string, params: TranslateParams = {}) {
  const value = key
    .split(".")
    .reduce((p: any, c) => (p && p[c]) || null, locale);
  let message = value || phrase;
  if (phrase.includes("||||")) {
    const [singular, plural] = phrase.split("||||");
    const count = params.smartCount || 0;
    message = count === 1 ? singular : plural;
  }
  // Low-level token interpolation
  return message.replace(
    /%{(\w+)}/g,
    (match: string, k: any) => `${params[String(k)] ?? ""}`
  );
}
