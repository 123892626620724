import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import { styleSheetDateTimeSelect } from "@airbnb/lunar/lib/components/DateTimeSelect/styles";

export const styleSpotTimeSelect: StyleSheet = (style) => {
  //https://github.com/airbnb/lunar/blob/master/packages/core/src/components/DateTimeSelect/styles.ts
  const defaultStyles: any = styleSheetDateTimeSelect(style);
  return {
    ...defaultStyles,
    selects: {
      ...defaultStyles.selects,
      justifyContent: "flex-end",
    },
  };
};
