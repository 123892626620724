import { StyleSheet } from "@airbnb/lunar/lib/hooks/useStyles";
import datePickerStyles from "@airbnb/lunar/lib/components/private/datePickerStyles";

export const fluidDatePickerStyleSheet: StyleSheet = (style) => {
  // NOTE:- https://github.com/airbnb/lunar/blob/master/packages/core/src/components/private/datePickerStyles.tsx
  const defaultStyles: any = datePickerStyles(style);
  return {
    ...defaultStyles,
    calendarContainer: {
      ...defaultStyles.calendarContainer,
      ...style.font.textSmall,
      fontWeight: "bold",
      width: "100%",
      border: "none",
      borderRadius: "none",
      boxShadow: "none",
      paddingLeft: 0,
      paddingRight: 0,
    },
    wrapper: {
      ...defaultStyles.wrapper,
      width: "100%",
    },
    month: {
      ...defaultStyles.month,
      width: `calc(50% - ${style.unit * 2}px)`,
      "@media": {
        [style.responsive.xsmall]: {
          width: "100%",
        },
      },
    },
    // Container of the months table
    months: {
      ...defaultStyles.months,
    },
    // The caption element, containing the current month's name and year
    caption: {
      ...defaultStyles.caption,
      ...style.font.textRegular,
    },
    day: {
      ...defaultStyles.day,
      width: `${100 / 7}%`,
      ":after": {
        content: '" "',
        display: "block",
        marginTop: "100%",
      },
      ":focus": {
        outline: "none",
      },
      "@selectors": {
        "> *": {
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    // Button to switch to the previous month
    navButtonPrev: {
      ...defaultStyles.navButtonPrev,
      left: 0,
      top: 1,
    },

    // Button to switch to the next month
    navButtonNext: {
      ...defaultStyles.navButtonNext,
      right: 0,
      top: 1,
    },
    // Added to the day's cell specified in the "disabledDays" prop
    modifier_disabled: {
      ...defaultStyles.modifier_disabled,
      opacity: 1,
    },
    // Added to the day's cell specified in the "selectedDays" prop
    modifier_selected: {},
    modifier_available: {
      // NOTE:- 予約可能な日
    },
    modifier_not_available: {
      // NOTE:- 予約不可能な日
    },
    modifier_sundays: {
      color: "#F04306",
    },
    modifier_saturdays: {
      color: "#48ACFF",
    },
  };
};
